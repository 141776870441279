import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import TechTag from "../components/TechTag"
import { FormattedMessage } from "react-intl"

import DesignCirclePartImage from "../images/mask@3x.png"
import DesignCornerImage from "../images/rectangle-md.svg"

import AppDevThumbnail from "../components/thumbnails/AppDevThumbnail"
import SoftDevThumbnail from "../components/thumbnails/SoftDevThumbnail"
import UiUxThumbnail from "../components/thumbnails/UiUxThumbnail"

const CaseStudyTemplate = ({
  title,
  mainDescription,
  mainDescriptionWithoutName,
  zoltUrl,
  listImage,
  challengeTitle,
  challengeDescription,
  challengeImage,
  userResultsTitle,
  userResults,
  userResultsImage,
  partnersResultsTitle,
  partnerResults,
  partnerResultsImage,
  resultTitle,
  resultsDescription,
  helmet,
}) => {
  const renderResults = (results) => (
    <div className="mt-5">
      {results.map((result) => (
        <div className="flex md:items-center mt-2">
          <span className="flex flex-shrink-0 w-2 h-2 bg-green-200 mt-2 mr-2 md:mt-0 md:mr-6 lg:mr-10 lg:w-3 lg:h-3" />
          <p className="text-lg">{result}</p>
        </div>
      ))}
    </div>
  )

  return (
    <div>
      {helmet || ""}
      <div className="relative lg:flex container mx-auto pt-12 px-10 lg:px-32">
        <img
          src={DesignCirclePartImage}
          alt=""
          className="absolute w-24 transform rotate-180"
          style={{ left: "50%", top: "5px" }}
        />
        <div className="self-center flex-1">
          <h1 className="font-semibold text-6xl tracking-wide text-gray-900 leading-none pr-48">
            {title}
          </h1>
          <p className="font-light text-lg text-gray-500 mt-10 lg:pr-24">
            <a
              href={zoltUrl}
              className="font-semibold text-green-500 hover:text-green-800"
            >
              ZOLT
            </a>
            {mainDescriptionWithoutName}{" "}
          </p>
          <p className="mt-10 mb-10">
            <TechTag>Ruby on Rails</TechTag>
            <TechTag>React JS</TechTag>
            <TechTag>React Native</TechTag>
            <TechTag>Stripe</TechTag>
          </p>
        </div>
        <div className="lg:flex-1 md:w-2/3 lg:w-auto">
          <img
            src={listImage.childImageSharp.fixed.src}
            alt=""
            className="-mb-8"
          />
        </div>
      </div>

      {/* CHALLENGE BLOCK */}
      <div
        className="relative overflow-hidden"
        style={{ backgroundColor: "#F9F9F9" }}
      >
        <div className="relative container mx-auto py-12 px-10 lg:py-20 lg:pr-32 lg:pl-20 xl:py-28">
          <div className="sm:mb-12 md:mb-0 md:grid md:grid-cols-10">
            <div className="flex col-start-6 col-span-5 order-2 md:items-center">
              <span className="flex flex-shrink-0 w-4 h-4 bg-green-200 mt-2 mr-2 md:mt-0 md:mr-6 lg:mr-10 lg:w-6 lg:h-6"></span>
              <h2 className="relative font-semibold text-xl lg:text-3xl">
                {challengeTitle}
              </h2>
            </div>
            <div className="relative row-span-2 col-span-5 order-1 mt-8 mb-2 py-4 md:my-auto xl:col-span-4">
              <img src={challengeImage.childImageSharp.fixed.src} alt="" />
            </div>
            <div className="col-start-6 col-span-5 order-3">
              <div className="md:pt-8 md:pl-4 lg:pl-16">
                <p className="relative text-sm text-gray-500 leading-6 lg:text-lg lg:leading-7">
                  {challengeDescription}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SOLUTION DESCRIPTION BLOCK 1 */}

      <div className="relative overflow-hidden">
        <div className="relative container mx-auto py-12 px-10 lg:py-20 lg:pr-32 lg:pl-20 xl:py-28">
          <div className="sm:mb-12 md:mb-0 md:grid md:grid-cols-10">
            <div className="relative row-span-2 col-span-5 order-1 mt-8 mb-2 py-4 md:my-auto xl:col-span-4">
              <img
                src={DesignCirclePartImage}
                alt=""
                className="absolute w-24 transform rotate-180 hidden md:block"
                style={{ left: "-1rem", top: "-3rem" }}
              />
              <img src={userResultsImage.childImageSharp.fixed.src} alt="" />
              <img
                src={DesignCirclePartImage}
                alt=""
                className="absolute w-24 transform hidden md:block"
                style={{ right: "-1rem", bottom: "-3rem" }}
              />
            </div>
            <div className="col-start-6 col-span-5 order-3">
              <div className="md:pt-8 md:pl-4 lg:pl-16">
                <p className="relative text-lg leading-6 mt-20 ml-12 lg:text-lg lg:leading-7 lg:ml-16 lg:mr-8">
                  <img
                    src={DesignCornerImage}
                    alt=""
                    className="absolute w-12 opacity-50 -top-12 -left-12 lg:-top-12 lg:-left-16"
                  />
                  <b>{userResultsTitle}</b>
                </p>
              </div>

              {renderResults(userResults)}
            </div>
          </div>
        </div>
      </div>

      {/* SOLUTION DESCRIPTION BLOCK 2 */}

      <div
        className="relative overflow-hidden"
        style={{ backgroundColor: "#F9F9F9" }}
      >
        <div className="relative container mx-auto py-12 px-10 lg:py-20 lg:pr-32 lg:pl-20 xl:py-28">
          <div className="sm:mb-12 md:mb-0 md:grid md:grid-cols-10">
            <div className="col-span-6 order-1">
              <div className="md:pt-8 md:pl-4 lg:pl-16">
                <p className="relative text-lg leading-6 mt-20 ml-12 lg:text-lg lg:leading-7 lg:ml-16 lg:mr-8">
                  <img
                    src={DesignCornerImage}
                    alt=""
                    className="absolute w-12 opacity-50 -top-12 -left-12 lg:-top-12 lg:-left-16"
                  />
                  <b>{partnersResultsTitle}</b>
                </p>
              </div>
              {renderResults(partnerResults)}
            </div>
            <div className="relative row-span-2 col-span-4 order-1 mt-8 mb-2 py-4 md:my-auto xl:col-span-4">
              <img src={partnerResultsImage.childImageSharp.fixed.src} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="" style={{ backgroundColor: "#f2f2f2" }}>
        <div className="relative container mx-auto py-24 px-10 lg:px-32">
          <img
            src={DesignCirclePartImage}
            alt=""
            className="absolute w-32"
            style={{ right: "2rem", bottom: "5rem" }}
          />
          <h2 className="font-semibold text-3xl mb-12">{resultTitle}</h2>
          <h2 className="font-sm text-xl mb-12">{resultsDescription}</h2>
        </div>
      </div>

      {/* USED TECHNOLOGIES BLOCK */}
      <div className="container mx-auto py-32 px-10 lg:px-32">
        <h3 className="text-center font-semibold text-2xl mb-12">
          <FormattedMessage id="case_study.tech_used" values={{ app: title }} />
        </h3>
        <div className="lg:flex lg:flex-row justify-center">
          <AppDevThumbnail className="lg:w-1/3" />
          <SoftDevThumbnail className="lg:w-1/3" />
          <UiUxThumbnail className="lg:w-1/3" />
        </div>
      </div>
    </div>
  )
}

const ZoltCaseStudy = ({ data, location }) => {
  const { markdownRemark: project } = data

  return (
    <Layout location={location}>
      <CaseStudyTemplate
        helmet={
          <Helmet>
            <title>{`${project.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${project.frontmatter.mainDescription}`}
            />
          </Helmet>
        }
        {...project.frontmatter}
      />
    </Layout>
  )
}

export default ZoltCaseStudy

export const pageQuery = graphql`
  query zoltProjectByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        mainDescription
        mainDescriptionWithoutName
        zoltUrl
        listImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        challengeTitle
        challengeDescription
        challengeImage {
          childImageSharp {
            fixed(width: 700, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        userResultsTitle
        userResults
        userResultsImage {
          childImageSharp {
            fixed(width: 700, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        partnersResultsTitle
        partnerResults
        partnerResultsImage {
          childImageSharp {
            fixed(width: 700, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        resultTitle
        resultsDescription
      }
    }
  }
`
